
.popover-card
{
    background-color: #EE3D8F;
    line-height: 20px;
    font-size: 17px !important;
    text-align: left;
    border: none;
    padding: 15px !important;
    margin-bottom: 0;
    min-width: 400px;

    .popover-card-button
    {
        cursor: pointer;
        color: #fff;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
        transition: 0.5s;

        &:hover
        {
            text-decoration: underline;
        }
    }
}

.widthPopper
{
    max-width: 450px;
}

@media (max-width: 576px)
{
    .tooltipTutorial
    {
        min-width: 80%;
        margin-left: 3%;
    }
}
