.e-404-comp {

  margin-top: 9rem;

  .jm1 {
    min-width: 200px;
    width:auto;
  }
  h2 {
    font-size: 22vw;
    font-size: max(22vw, 90px);
    margin-bottom: 0px;
    line-height: 38vw;
  }
  p {
    font-size:18px;
    margin:1rem;
  }
  a {
    font-size:18px;
    font-family: Kanit, sans-serif;
  }

  @media screen and (max-width: 768px) {

    h2 {
      max-height: 34vh;
      padding-top: 2vh;
      min-height: 130px;
      max-height: 230px;
    }

    .jm1 {
      height: 330px !important;
    }

  }

}

