@media (max-width: 990px) {
    .podcast {
      .podcast-season {min-width: 280px;}
      .episode{
        min-width: 280px;
        position: relative;

        .miniatura {
          max-width: 60px;
          max-height: 60px;
          position: absolute;
        }

        .primario{position: relative; width: 100%;}

        .main {
          height: auto;
          margin: 0 0 0 4rem;
          overflow: visible;

          .ep-title{
            display: -webkit-box !important;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            height: auto;
            text-overflow:ellipsis;
            overflow:hidden;
            white-space:break-spaces;
            margin-bottom:0;
            word-break:break-all;
          }

          .collapsed-sinops {
            display: -webkit-box !important;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            height: 92px;
            text-overflow:ellipsis;
            overflow:hidden;
            white-space:break-spaces;
            margin-bottom:0;
            word-break:break-all;
          }
        }

        .botonera {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          height: 60px;
          position: absolute;
          right: 0;
          bottom: -10px;
          width: 40%;
          max-width: 160px;
          min-width: 128px;
          min-height: auto;

          .side-btn{height: 45px;}
        }
      }
      .episode.selected{
        .main{
          height: auto;

          .expanded-sinops {max-height: none;}
        }
      }

      .podcast-transcript{
        .downl-transcr-btn{margin: 6px!important;}
        .collapsing{height: auto;}
        .main{margin:0;}
      }

      .podcast-aud-player {
        margin-top: -10px;
        height: 130px;
        margin-left: -70px;

        .audioplayer-time {left:4px; margin: 24px 0 !important;}
      }
    }
  }