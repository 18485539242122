.icono {
    background-position: center center;
    background-size: contain;
    height: 60px;
    width: 60px;
    position: relative;
    margin-right: auto;
    margin-left: 20px;
    margin-bottom: 0px;
    margin-top: -65px;
    border-radius: 50%;
    box-shadow: none!important;
}

.icono-buscador {
    background-position: center center;
    background-size: contain;
    position: relative;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    box-shadow: none!important;
}

.icono-recursos {
    background-position: center center;
    background-size: contain;
    position: relative;
    border-radius: 50%;
    margin-left: 0px;
    margin-top: 0;
    width: 30px;
    height: 30px;
    box-shadow: none!important;
}

.icono-carousel {
	background-position: center center;
    background-size: contain;
    height: 20px;
    width: 20px;
    position: relative;
    margin: -20px 0px 0px 10px;
    border-radius: 2px;
}

.icono-carousel-buscador {
	background-position: center center;
    background-size: contain;
    height: 30px;
    width: 30px;
    position: absolute;
    margin: -25px 0px 0px 5px;
    border-radius: 2px;
	top: 35px;
	left: 5px;
	z-index: 1004;
	border-radius: 50%;
}

.icono-home {
	background-position: center center;
	background-size:contain;
	position: relative;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 0px;
	background-repeat: no-repeat;
}

.icono-home-lg {
	background-position: center center;
	background-size:contain;
	position: relative;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 0px;
	background-repeat: no-repeat;
}

.icono-home-xl {
	background-position: center center;
	background-size:contain;
	position: relative;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 0px;
	background-repeat: no-repeat;
}

.icono-xs {
	background-position: center center;
	background-size:contain;
	height: 40px;
	width: 40px;
	position: relative;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 20px;
	margin-top: -10px;
}

.icono-act {
	background-position: center center;
	background-size:contain;
	height: 60px;
	width: 60px;
	position: relative;
	margin: 1px;
	padding: 0px;
}

.icono-user-panel {
	background-position: center center;
    background-size: contain;
    height: 0.9rem;
    width: 0.9rem;
    position: relative;
    margin: 0px 5px 0px 0px;
    border-radius: 2px;
    vertical-align: middle;
}

.grayed-out {
	 opacity: 0.3;
    filter: alpha(opacity=30); /* For IE8 and earlier */
	cursor: not-allowed;
}

.panel {background-image: url(iconos/panel.svg);}
.misaulas {background-image: url(iconos/misaulas.svg);}
.miformacion {background-image: url(iconos/miformacion.svg);}
.mislikes {background-image: url(iconos/mislikes.svg);}
.mibiblioteca {background-image: url(iconos/mibiblioteca.svg);}
.miperfil {background-image: url(iconos/miperfil.svg);}
.mispostitulos {background-image: url(iconos/MEDALLA_MIS_POSTULOS.svg);}

.galerias {background-image: url(iconos/galeria_de_imagenes.svg);}
.videos {background-image: url(iconos/video.svg);}
.juegos {background-image: url(iconos/interactivo.svg);}
.libros {background-image: url(iconos/libro_electronico.svg);}
.textos {background-image: url(iconos/texto.svg);}
.clase {background-image: url(iconos/clase.svg);}
.coleccion {background-image: url(iconos/coleccion.svg);}
.icono_podcast {background-image: url(iconos/podcast.png);}
.encuentro {background-image: url(iconos/encuentro.svg);}
.dialogos {background-image: url(iconos/audio.svg);}
.educacion {background-image: url(iconos/educacion.svg);}
.expertos {background-image: url(iconos/expertos.svg);}
.icono_api {background-image: url("../images/JM_API.svg");}
.icono_oai {background-image: url("../images/JM_OAI.svg");}
.misdatos {background-image: url(iconos/misdatos.svg);}
.mislikes {background-image: url(iconos/mislikes.svg);}
.favorito {background-image: url(iconos/favorito-svg.svg);}
.contacto {background-image: url(iconos/contacto.svg);}
.publicaciones {background-image: url(iconos/publicaciones.svg);}
.pizarron {background-image: url(iconos/pizarron.svg);}
.computadora {background-image: url(iconos/computadora.svg);}
.preguntas_frecuentes {background-image: url(iconos/preguntas_frecuentes.svg);}
.biblioteca_home {background-image: url(iconos/bibliotecaHome.svg);}

.icono_play {
    height: 80px!important;
    width: 140px!important;
    position: relative!important;
    margin: 0px;
    left: 0!important;
    padding: 0px;
    top: 0px!important;
}

.play {
    background-image: url(iconos/play.svg);
    position: relative;
    top: 0px;
    z-index: 1004;
    width: 50px;
    height: 50px;
    left: 23px;
    cursor: pointer;
}

.play:hover {
    opacity: 0.5;
	transition: 0.4s;
}
.icon-shadow {
  -webkit-filter: drop-shadow( 2px 2px 4px rgba(0, 0, 0, 0.3));
  filter: drop-shadow( 2px 2px 4px rgba(0, 0, 0, 0.3));
}