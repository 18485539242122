
.podcast {
  .podcast-season {
    width:100%;
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding: 1rem 1.4rem;
    margin-bottom: 0.8rem;
    transition:0.3s;
    min-width: 540px;
    background-color:#ECE7E7;
    min-height: 86px;

    strong{margin-right: 18px;}
    }
  .podcast-season.active{
    color:white;
    background-color:#2EB5E6;
  }
  .season-arrow {
    background: #2EB5E6;
    color:white;
    cursor:pointer;
    display:inline;
    justify-self:flex-end;
    border-radius:50%;
    margin:0 0.65rem;
    transition:0.25s !important;
    transform:scale(2.2);
  }
  .season-arrow.active {
    background:white;
    color:#2EB5E6;
    transform:scale(2.2) rotate(180deg);
  }

  .miniatura {
    border:inherit;
    display:inline;
    max-width:145px;
    max-height:145px;
  }

  .episode{
    width:100%;
    margin-bottom:0.7rem;
    display:flex;
    border:1px solid #707070;
    padding: 1rem;
    background-color: white;
    transition: 0.4s;
    min-width: 540px;
  }
  .episode.selected{
    background-color: inherit;
    border:1px solid #cfcdcc00;
    background-color: #E3E1E0;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: calc(100% - 165px);
      .collapse.show {display: flex;}
      .collapsing {
        display: flex;
        height: auto;
        }

      .no-transcr{margin-top:2rem;}

      }
      .show{position: relative;}

      .downl-transcr-contain {
      min-width: 4.2rem;
      position: absolute;
      right: -6px;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;

      .downl-transcr-btn {
        cursor: pointer;
        border:1px solid black;
        border-radius:50%;
        padding:0.6rem !important;
        transform: scale(0.73);
        width: 4.2rem;
        height: 4.2rem;

          img {transform:scale(0.8);}
      }
  }

  .main {
    display:flex;
    flex-direction:column;
    margin:0 0 0 0.2rem;
    padding:0 0.6rem;
    overflow:hidden;
    flex-grow: 1;
  }
  .main strong {
    height: 12px;
    font-weight: 900;
  }

  .ep-title {
    text-overflow:ellipsis;
    overflow:hidden;
    white-space:nowrap;
    margin-bottom:4px;
    height: 30px;
  }
  .ep-title strong { font-weight: 900; }

  .collapsed-sinops {
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 46px;
    text-overflow:ellipsis;
    overflow:hidden;
    white-space:break-spaces;
    margin-bottom:0;
    word-break:break-all;
  }
  .expanded-sinops {
    display: -webkit-box !important;
    -webkit-line-clamp: initial;
    -webkit-box-orient: vertical;
    max-height:500px;
    margin-bottom: 0;
  }

  .primario {
    display: flex;
    flex-direction: row;
  }

  .botonera {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 174px;
    height: auto;
  }
  .side-btn {
    cursor: pointer;
    border:1px solid black;
    border-radius:50%;
    padding:0.6rem;
    margin: 0.4rem;
    transform: scale(1.1);
  }
  .side-btn-expanded {
    cursor: pointer;
    border:1px solid black;
    border-radius:50%;
    padding:0.6rem;
    margin: 0.4rem;
    background-color: #fff;
    transform: scale(1.1);
  }
  .side-btn[disabled]{
    color: #C8C8C8;
    border: 1px solid #C8C8C8;
    cursor:auto;
  }

  .podcast-transcript {
    display: flex;
    flex-direction: row;
    width: 80%;
  }

  .arrow-expand {
    cursor:pointer;
    transform:rotate(0deg);
    transition:transform 0.25s;
  }
  .arrow-collapse {
    cursor:pointer;
    transform:rotate(180deg);
    transition:transform 0.25s;
  }




  .podcast-aud-player{
    .audioplayer {
      height: 60px;
      display: flex;
      flex-direction: row;
      box-sizing: border-box;
      margin: 0px;
      padding: 0px;
      width: 98%;
      align-items: center;
      border: none;
      border-radius: 4px;

      .pc-player-vol-wrapper{
        height: 140px;
        align-self: flex-end;
        display: flex;
        flex-direction: column-reverse;
        width: 12px;
        margin: 0 0 16px 8px;
      }

      .audioplayer-bar {margin: 0px 12px 0px 22px;}
      .audioplayer-volume-button {
        margin-top: 4px;
        span:after {top:-1px;}
      }
      .audioplayer-volume-bar.vertical.show {opacity: 1; pointer-events: auto;}
      .audioplayer-volume-bar.vertical {
        opacity:0;
        cursor:unset;
        pointer-events: none;
      }

      .audioplayer-time {font-size: 0.95rem;}
    }
  }

}