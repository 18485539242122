@font-face {
  font-family: 'Encode Sans';
  font-weight: 200;
  src: url('../fonts/encode-sans/EncodeSans-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Encode Sans';
  font-weight: 300;
  src: url('../fonts/encode-sans/EncodeSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Encode Sans';
  font-weight: normal;
  src: url('../fonts/encode-sans/EncodeSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Encode Sans';
  font-weight: 500;
  src: url('../fonts/encode-sans/EncodeSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Encode Sans';
  font-weight: 600;
  src: url('../fonts/encode-sans/EncodeSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Encode Sans';
  font-weight: 700;
  src: url('../fonts/encode-sans/EncodeSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Encode Sans';
  font-weight: 800;
  src: url('../fonts/encode-sans/EncodeSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Encode Sans';
  font-weight: 900;
  src: url('../fonts/encode-sans/EncodeSans-ExtraBold.ttf') format('truetype');
}

.badge {
  line-height: 2;
}

.texto-truncate > p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
  margin-bottom: 0;
}
.texto-truncate2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.texto-truncate2 > * {
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.parrafo-sin-margen-abajo > p {
  margin-bottom: 5px;
}

.overflow-text {
  height: inherit;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 7;
  -moz-line-clamp: 7;
  -ms-line-clamp: 7;
  line-clamp: 7;
  overflow: hidden;
}

.overflow-text-list {
  height: 75px;
  margin-bottom: 1rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  -ms-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
}

.overflow-header {
  height: 21px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  -ms-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
}

.overflow-text > * {
  line-height: 1.5;
  margin: 0px;
}

.overflow-text-list > * {
  line-height: 1.5;
  margin: 0px;
}

.body-resource-height {
  height: 100%;
}

.h-70 {
  height: 70%;
}

.nav-pills .nav-link {
  color: #34babc;
}

.auxMapSVG {
  fill: rgba(255, 255, 255, 0.7);
  stroke: rgb(50, 187, 202);
  stroke-width: 2.4387px;
}

.auxMapSVG:focus {
  fill: rgb(50, 187, 202);
}

.auxMapSVG:hover {
  fill: rgba(255, 255, 255, 0.7);
}

.faq-header {
  padding: 10px;
  margin-bottom: 0;
  background-color: transparent;
  position: relative;
  z-index: 1002;
  height: fit-content;
}

.card-footer-transparent {
  background-color: transparent;
  margin-top: -30px;
  padding: 10px;
}

.notMarginButton > * {
  margin-bottom: 0px;
}

.carousel-title-description {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  padding: 1rem;
  color: #fff;
  text-align: left;
}

.aux-class {
  position: relative;
}

.audio-responsive {
  width: 100%;
}

.iconos-home-recursos {
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.iconos-home-recursos:hover {
  transform: scale(1.1);
}

.links-nav-mis-favoritos {
  color: #000;
  padding: 10px 0px 10px 0.5rem !important;
  margin: 0;
  font-size: 18px !important;
  background-color: #fff;
}

/* Santiago */
.bs-popover-auto[x-placement^="right"] > .arrow:after,
.bs-popover-right > .arrow:after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #EE3D8F;
}

.bs-popover-auto[x-placement^="right"] > .arrow:before,
.bs-popover-right > .arrow:before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-popover-auto[x-placement^="left"] > .arrow:after,
.bs-popover-left > .arrow:after {
  border-left-color: #EE3D8F;
}

.bs-popover-auto[x-placement^="top"] > .arrow:before,
.bs-popover-top > .arrow:before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: none;
}

.bs-popover-auto[x-placement^="top"] > .arrow:after,
.bs-popover-top > .arrow:after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #EE3D8F;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-top: none;
  border-left: none;
  border-right: none;
}

@media (max-width: 576px) {
  .listTitle {
    font-family: "Kanit", sans-serif;
    font-size: 16px;
    position: relative;
    top: 4px;
    line-height: 16px;
  }

  .listSubtitle {
    position: relative;
    top: 12px;
    font-size: 13px!important;
}

  .listAdicionales {
    position: absolute;
    top: 20px;
    height: 70px;
    right: -10px;
  }

  .listVer {
    position: relative;
    bottom: -20px;
    float: right;
    font-size: 20px;
    right: 10px;
  }

  .list-group-item {
    padding: 1rem 0rem 1.75rem 0rem;
  }

}

/* Santiago */

@media screen and (max-width: 575px) {
  .view-home-center {
    text-align: center;
  }
}

@media only screen and (min-width: 1289px) {
  .jm2-our {
    height: 200px !important;
    margin-bottom: 20px;
    margin-top: 0px !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1288px) {
  .jm2-our {
    height: 325px !important;
    margin-bottom: 20px;
    margin-top: 0px !important;
  }
}

@media screen and (max-width: 768px) {
  .jm2-our {
    height: 200px !important;
    margin-bottom: 20px;
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 770px) {
  #breadCrumb {
    margin-left: 5%;
    margin-top: 20px;
  }
}

#user-menu {
  max-height: 250px;
}

@media screen and (min-width: 769px) {
  #user-menu {
    overflow: hidden!important;
    max-height: fit-content!important;
  }
}