
$transcription-box-gap: 40px;

.transcription-box
{
    margin-bottom: 40px;
    display: flex;
    gap: 40px;
    align-items: start;
    justify-content: space-between;

    &.hide
    {
        display: none;
    }

    > div
    {
        word-break: break-word;
    }
}

//@media screen and (max-width: 768px)
@media screen and (max-width: 991px)
{
    .transcription-box
    {
        gap: 20px;
        flex-direction: column-reverse;
    }
}
