$black: black;
$white: white;
$teal: #37bbed;
$celeste: #2eb5e6;
$red: red;

@import 'audioplayer';
@import 'transcription';
@import 'favorite_resource_list';
@import 'resource_action_box';
@import 'popover';
@import 'embed';

@import 'podcasts/podcasts';
@import 'podcasts/podcasts_mobile';

@import 'error_404';