body {
  font-family: "Encode Sans", sans-serif !important;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.navbar-nav {
  font-family: "Encode Sans", sans-serif !important;
}

.footer-container {
  background-color: #2eb5e6;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Encode Sans", sans-serif !important;
  letter-spacing: 0px !important;
  /* text-transform: lowercase!important; */
}

.h1,
h1:first-letter {
  text-transform: uppercase !important;
}

.h2,
h2:first-letter {
  text-transform: uppercase !important;
}

.h3,
h3:first-letter {
  text-transform: uppercase !important;
}

.h4,
h4:first-letter {
  text-transform: uppercase !important;
}

.h5,
h5:first-letter {
  /* text-transform: uppercase!important; */
}

.h6,
h6:first-letter {
  text-transform: uppercase !important;
}

h6 p {
  font-weight: 400;
}

a {
  color: #0072bb;
  background-color: transparent;
  font-weight: 500;
}

a:hover {
  color: #37bbed;
  text-decoration: underline;
}

footer a {
  color: white;
  font-weight: 500;
  transition: 0.3s;
}

footer a:hover {
  opacity: 0.7;
  transition: 0.3s;
}

.recurso-title-link {
  text-decoration: none !important;
}

.form-control:focus {
  border-color: #37bbed !important;
}

/* .nav-item .badge {
    margin-top: 20px;
} */

.logo-jm {
  height: 110px !important;
  width: auto !important;
}

.btn-outline-primary {
  color: #37bbed;
  border-color: #37bbed;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #37bbed;
  border-color: #37bbed;
}

.btn-outline-primary:focus {
  color: #fff;
  background-color: #37bbed;
  border-color: #37bbed;
}

button.btn.btn-sm.btn-secondary.float-right.small {
  text-transform: lowercase;
  letter-spacing: 0px;
  font-weight: 400;
  padding: 5px 15px;
}

button.btn.btn-sm.btn-secondary.float-right.small:first-letter {
  text-transform: uppercase;
}

.btn-conectar-igualdad:hover {
  background-color: #0481d2;
  border-color: #0481d2;
}

.btn-conectar-igualdad,
.btn-conectar-igualdad:focus,
.btn-conectar-igualdad.focus,
.btn-conectar-igualdad.disabled,
.btn-conectar-igualdad:disabled,
.btn-conectar-igualdad:not(:disabled):not(.disabled):active,
.btn-conectar-igualdad:not(:disabled):not(.disabled).active,
.show > .btn-conectar-igualdad.dropdown-toggle {
  background-color: #0072bb;
  border-color: #0072bb;
}

.carousel-dots li.slick-active div {
  background-color: #37bbed !important;
  border-color: #37bbed !important;
}

.carousel-dots li div {
  background-color: #adb5bd !important;
  border-color: #adb5bd !important;
}

.card-title {
  color: #37bbed !important;
  /* text-transform: lowercase!important; */
}

.recurso-title-no-transform {
  text-transform: none !important;
}

.card-title:first-letter {
  /* text-transform: uppercase!important; */
}

.display-5 span {
  font-size: 3rem;
  line-height: 1.2;
  color: #5a5a5a !important;
}

h5 span {
  color: #5a5a5a !important;
}

.badge-primary {
  color: #fafbfc !important;
  background-color: #37bbed;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #50535c;
  border-color: #50535c;
  border-radius: 30px;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  justify-content: center;
  margin-top: 20px;
}

.page-link {
  border: none;
}

a.page-link {
  color: #0072bb !important;
  font-weight: 500;
  margin: 0px 10px;
  padding: 10px 15px;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
  border-radius: 30px !important;
}

.img-event::before {
  content: "";
  width: 100%;
  height: 80px;
  background-color: #e2e2e2;
  position: absolute;
  clip-path: polygon(0 0, 0 80%, 100% 100%, 100% 0);
}

img.group.list-group-image.img-fluid.card-img-top {
  opacity: 0;
}

.badge-primary {
  color: #000 !important;
  background-color: #e2e2e2 !important;
}

.badge-primary:hover {
  color: #000 !important;
  background-color: #a7a7a7 !important;
}

.badge-secondary {
  color: #000 !important;
  background-color: #e2e2e2 !important;
}

.badge-secondary:hover {
  color: #000 !important;
  background-color: #a7a7a7 !important;
}

.sidebar-body a.badge.badge-primary {
  color: #000 !important;
  font-weight: 300 !important;
}

ul.MuiList-root.MuiMenu-list.MuiList-padding strong {
  margin-left: 10px;
  color: #50535c;
}

.sidebar-body a:hover {
  color: #37bbed;
}

.sidebar-body a {
  color: #0072bb;
  font-weight: 500;
}

.overflow-text-list {
  height: 85px !important;
}

.texto-truncate > p {
  -webkit-line-clamp: 2 !important;
}

.bs-popover-auto[x-placement^="right"] > .arrow:before,
.bs-popover-right > .arrow:before {
  border-right-color: #ee3d8f !important;
}

.bs-popover-auto[x-placement^="right"] > .arrow:after,
.bs-popover-right > .arrow:after {
  border-right-color: #ee3d8f !important;
}

.bs-popover-auto[x-placement^="bottom"] > .arrow:after,
.bs-popover-bottom > .arrow:after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem;
  border-bottom-color: #ee3e8e;
}

.bs-popover-auto[x-placement^="bottom"] > .arrow:before,
.bs-popover-bottom > .arrow:before {
  border-bottom-color: #ee3e8e !important;
}

.bs-popover-auto[x-placement^="bottom"] > .arrow:after,
.bs-popover-bottom > .arrow:after {
  border-bottom-color: #ee3e8e !important;
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  #searchResults {
    margin-top: -110px;
  }
}

@media screen and (max-width: 991px) {
  .navbar-collapse {
    background-color: #333;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-left: 0px;
    margin-right: 0px;
    padding-right: 20px;
    padding-left: 20px;
    margin-top: -5px;
    line-height: 40px;
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 1288px) {
  .main-img {
    padding: 50px 50px 30px 400px !important;
  }
  .s1 {
    font-size: 35px !important;
    line-height: 40px !important;
  }
  .large {
    font-size: 100%;
  }
  .card-body-md {
    margin-top: 0px !important;
  }
  .icono-carousel-buscador {
    width: 20px !important;
    height: 20px !important;
  }
}

@media only screen and (max-width: 1560px) {
  h3 {
    font-size: 1.25rem !important;
    letter-spacing: 2px;
  }
}

@media only screen and (min-width: 1025px) {
  .icono-home {
    height: 130px;
    width: 130px;
  }
  .icono-home-lg {
    height: 150px;
    width: 150px;
  }
  .icono-home-xl {
    height: 250px;
    width: 250px;
    margin-top: 0px;
  }
  .icono-buscador {
    height: 50px;
    width: 50px;
  }
  .jm-buscador {
    max-height: 366px;
  }
  .card-search {
    height: 400px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .s1 {
    font-size: 20px !important;
    line-height: 20px !important;
  }
  .large {
    font-size: 100%;
  }
  h1,
  .h1 {
    font-size: 1.5rem;
  }
  h2,
  .h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.25rem !important;
  }
  h6,
  .h6 {
    font-size: 0.75rem !important;
  }
  .card-search {
    height: 400px !important;
  }
  .jm-buscador {
    margin-top: 7px !important;
    max-height: 339px;
  }
  .icono-home {
    height: 100%;
    width: 100%;
  }
  .icono-home-lg {
    height: 160px;
    width: 160px;
  }
  .icono-home-xl {
    height: 100px;
    width: 100px;
    margin-top: 0px;
  }
  .max-ch {
    display: -webkit-box;
    -webkit-line-clamp: 5 !important;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .logos-footer {
    width: 100% !important;
    padding-left: 0% !important;
    padding-right: 0% !important;
    margin-bottom: 20px;
  }
  .footer {
    min-height: 150px !important;
    padding: 0px !important;
  }
  .legales {
    margin-top: 10px !important;
  }
  .icono {
    height: 40px !important;
    width: 40px !important;
    border-radius: 5px !important;
    margin-top: -17% !important;
  }
  .card-body.card-lg {
    margin-top: 110px !important;
    line-height: 16px;
  }
  .card-title {
    font-size: 14px !important;
  }
  .card-text:last-child {
    font-size: 12px !important;
  }
  .icono-buscador {
    width: 70px !important;
    height: 70px !important;
    border-radius: 5px !important;
    margin-top: 30px !important;
  }
  #sidebar .sidebar-header {
    padding: 26px 20px !important;
  }
  #content {
    overflow: hidden;
  }
  .card-body-md {
    margin-top: 0px !important;
    font-size: 13px !important;
    line-height: 10px !important;
  }
  .searchResultBox {
    margin-top: 0px !important;
    height: 180px !important;
  }
  .icono-carousel-buscador {
    display: none !important;
  }
}

.footerDatos {
  text-align: right;
}

.vidActive {
  background-color: #1c2023;
  border-top: none;
  cursor: not-allowed;
}

.vidActive .card-title {
  opacity: 0.4 !important;
  cursor: not-allowed;
}
.vidActive p {
  opacity: 0.4 !important;
  cursor: not-allowed;
}
.vidActive .icono_play {
  opacity: 0.4 !important;
  cursor: not-allowed;
}
.vidGallery {
  max-height: 750px;
  overflow-y: scroll;
}

.cool-link {
  color: #666;
  display: inline-block;
  margin: 0;
  text-transform: uppercase;
}
.cool-link:after {
  display: block;
  content: "";
  border-bottom: solid 2px #fff;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}
.cool-link:hover:after {
  transform: scaleX(1);
}
.cool-link.fromRight:after {
  transform-origin: 100% 50%;
}
.cool-link.fromLeft:after {
  transform-origin: 0% 50%;
}

li.nav-item.active:after {
  border-bottom: 2px solid #fff;
  content: "";
  display: block;
  margin: -10px auto;
  width: 62%;
  padding-top: 0px;
}

.attachedLabel {
  position: relative;
  left: 8px;
  top: 18px;
  background: white;
  padding: 0px 6px;
  letter-spacing: 0px;
  font-size: 13px;
  color: #37bbed;
  font-weight: bold;
  text-transform: uppercase;
}

.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}
.ripple:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.5s, opacity 1s;
}
.ripple:active:after {
  transform: scale(0, 0);
  opacity: 0.2;
  transition: 0s;
}

.tc-1 {
  color: #822b86 !important;
}
.tc-2 {
  color: #e69732 !important;
}
.tc-3 {
  color: #1ac2c2 !important;
}
.tc-4 {
  color: #119d57 !important;
}
.tc-5 {
  color: #4f2780 !important;
}
.tc-6 {
  color: #f7be4a !important;
}
.tc-7 {
  color: #238da5 !important;
}
.tc-8 {
  color: #29a56a !important;
}

.main-img {
  background-image: url(../images/slider/bg-1.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: auto;
  margin-top: 110px;
  margin-left: 0px;
  position: relative;
  padding: 50px 100px 50px 400px;
  color: white;
  min-height: 380px;
}

.navbar-dark .navbar-nav .nav-link {
  font-weight: 900;
  color: white !important;
}

.hiddenRow {
  padding: 0px !important;
}

/* Usuario Logueado */
.profile {
  min-height: 355px;
  display: inline-block;
}
figcaption.ratings {
  margin-top: 20px;
}
figcaption.ratings a {
  color: #f1c40f;
  font-size: 11px;
}
figcaption.ratings a:hover {
  color: #f39c12;
  text-decoration: none;
}
.divider {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.emphasis {
  border-top: 4px solid transparent;
}
.emphasis:hover {
  border-top: 4px solid #1abc9c;
}
.emphasis h2 {
  margin-bottom: 0;
}
span.tags {
  background: #1abc9c;
  border-radius: 2px;
  color: #f5f5f5;
  font-weight: bold;
  padding: 2px 4px;
}
.dropdown-menu {
  background-color: #fff;
  color: #34495e;
  width: 250px;
  margin-left: 0px;
  left: 0;
}
.dropdown-menu .divider {
  background: none;
}
.dropdown-menu > li > a {
  color: #f5f5f5;
}
.dropup .dropdown-menu {
  margin-bottom: 10px;
}
.dropup .dropdown-menu:before {
  content: "";
  border-top: 10px solid #34495e;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -10px;
  z-index: 10;
}

span.badge.badge-xs.badge-secondary {
  font-size: 50%;
}
.tech_logo {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-height: 100px;
  width: 100%;
}

.jm-buscador {
  position: absolute;
  margin-top: 0;
  left: 30px;
  width: 350px;
  bottom: 0;
}

.lh-12 {
  line-height: 12px;
}
.lh-15 {
  line-height: 15px;
}
.lh-20 {
  line-height: 20px;
}
.lh-30 {
  line-height: 30px;
}
.lh-40 {
  line-height: 40px;
}
.lh-50 {
  line-height: 50px;
}
.main_content {
  margin-top: 120px;
}
/*Password strength meter */
*:focus {
  outline-style: none;
}

#progress {
  height: 8px;
  width: 100%;
  margin-top: 0.6em;
  overflow: hidden;
  border-radius: 3px;
  background-color: #f5f5f5;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

#progress-bar {
  width: 0%;
  height: 8px;
  transition: width 500ms linear;
}

.tab_buttons {
  width: auto;
  text-align: center;
}

.progress-bar-danger {
  background: #d00;
}

.progress-bar-warning {
  background: #f50;
}

.progress-bar-success {
  background: #080;
}
/*Load more */
ul {
  list-style: none;
}
a {
  text-decoration: none;
}

.more {
  border-radius: 5px;
  padding: 5px 10px;
  color: #070707;
  cursor: pointer;
  margin-left: 0px;
  margin-right: 15px;
  margin-top: 15px;
  letter-spacing: 0px;
  font-family: "Encode Sans", sans-serif !important;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  background-color: #e2e2e2;
}

.more:hover {
  background-color: #e2e2e2;
}

.term-list {
  padding-left: 20px;
}

/*MAP SVG */
.mapsvg-controller-view-wrap.nano {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
}

.mapsvg-popover .mapsvg-controller-view-toolbar {
  height: 20px !important;
  width: 20px !important;
  float: right !important;
}

/*Cards*/
.cardRecursos {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: none;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
  max-width: 350px;
}

.cardRecursos > hr {
  margin-right: 0;
  margin-left: 0;
}

.cardRecursos > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.cardRecursos > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.cardRecursos > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.cardRecursos-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.8rem 1.2rem;
}

.cardRecursos-title {
  margin-bottom: 0.75rem;
  color: #5c318d;
  line-height: 1;
  font-weight: 600;
}

.cardRecursos-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.cardRecursos-text:last-child {
  margin-bottom: 0;
  margin-top: 20px;
  font-size: 14px !important;
}

.cardRecursos-link:hover {
  text-decoration: none;
}

.cardRecursos-link + .cardRecursos-link {
  margin-left: 1.25rem;
}

.cardRecursos-header {
  padding: 0px;
  margin-bottom: 0;
  background-color: transparent;
  position: absolute;
  z-index: 1002;
  right: 10px;
  top: 10px;
}

.cardRecursos-header:first-child {
  border-radius: 0px;
}

.cardRecursos-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.cardRecursos-footer {
  padding: 10px;
  background-color: white;
  margin-top: -30px;
}

.cardRecursos-footer:last-child {
  border-radius: 0px;
}

.cardRecursos-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.cardRecursos-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.cardRecursos-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.cardRecursos-img,
.cardRecursos-img-top,
.cardRecursos-img-bottom {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.cardRecursos-img,
.cardRecursos-img-top {
  width: 100%;
  height: auto;
  -webkit-clip-path: polygon(0% 0%, 0% 80%, 100% 100%, 100% 0);
  clip-path: polygon(0% 0%, 0% 80%, 100% 100%, 100% 0);
}

.cardRecursos-img,
.cardRecursos-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.cardRecursos-deck .cardRecursos {
  margin-bottom: 15px;
}

.cardRecursos-body.cardRecursos-lg {
  margin-top: 48%;
}

.cardRecursos-body.cardRecursos-body-md {
  margin-top: 80px !important;
}

.cardRecursos-body.cardRecursos-sm {
  margin-top: 130px !important;
}

.cardRecursos-header.cardRecursos-header-lg {
  top: 0px !important;
  right: 0px !important;
}

.cardRecursos-img,
.cardRecursos-img-top-buscador,
.cardRecursos-img-bottom {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
}

.cardRecursos-img,
.card-img-top-buscador {
  width: 100%;
  height: auto;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card.card-dark {
  background: #343a40;
  border-radius: 0px;
  margin-bottom: 0px !important;
}

.fa-small {
  width: 5px !important;
  height: 5px !important;
  padding: 0px !important;
  color: #000 !important;
  position: relative;
}

/*Social buttons */
.fa-so {
  font-size: 30px;
  width: 30px;
  height: 30px;
  text-align: center;
  text-decoration: none;
  padding: 7px;
  border-radius: 5px;
  margin: 0px 5px;
}

/* Facebook */
.fa-facebook {
  background: #3b5998;
  color: white;
  transition: 0.3s;
}

.fa-facebook:hover {
  background: #293e65;
  color: white;
  transition: 0.3s;
}

/* Twitter */
.fa-twitter {
  background: #55acee;
  color: white;
  transition: 0.3s;
}

.fa-twitter:hover {
  background: #3c7cad;
  color: white;
  transition: 0.3s;
}

/* Whatsapp */
.fa-whatsapp {
  background: #25d366;
  color: white;
  transition: 0.3s;
}

.fa-whatsapp:hover {
  background: #24a259;
  color: white;
  transition: 0.3s;
}

/* Email */
.fa-envelope {
  background: #d32525;
  color: white;
  transition: 0.3s;
}

.fa-envelope:hover {
  background: #a02121;
  color: white;
  transition: 0.3s;
}

/* Like */
.fa-heart-o {
  background: #fff3f3;
  /* color: #e40f5c !important; */
  transition: 0.3s;
}

.fa-heart-o:hover {
  background: #f5e2e2;
  /* color: #e40f5c !important; */
  transition: 0.3s;
}

.fa-heart {
  background: #ffe9e9;
  /* color: #e40f5c !important; */
  transition: 0.3s;
}

/* Like button */
.btn:active,
.btn:focus,
.btn:active:focus,
.btn:focus {
  outline: none !important;
}

.swap {
  cursor: pointer;
  /* margin-left: 2%; */
}

.swap.detail {
  margin-left: 0 !important;
}

button > span {
  color: #e40f5c !important;
  font-size: 20px;
}

.font-22px {
  font-size: 22px !important;
}

.btn-round {
  padding: 5px 15px;
  border-radius: 50%;
  text-align: center;
}

.search_main {
  position: absolute;
}

#sresult {
  position: relative;
  background-color: #fff;
  border: 1px solid #cccccc;
  display: none;
}

#searchResults {
  display: none;
}

.searchResultBox {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  margin-top: -125px;
  position: relative;
  z-index: 1003;
  height: auto;
  padding: 5px 0px 60px 0px;
}

.jm_buscador {
  position: absolute;
  width: 115%;
  height: 115%;
  margin-left: -50px;
  margin-top: -1px;
}
#lacita {
  position: relative;
  top: -8px;
  font-size: 12px;
  right: 10px;
}
/*Card Search*/
.card-search {
  background-image: url(../images/slider/bg-1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  border-radius: 10px;
  margin-top: 40px;
  margin-bottom: 30px;
  padding: 20px;
  height: 410px;
}

.card-login3 {
  background-color: white;
  width: 100%;
  height: auto;
  min-height: 50vh;
  position: relative;
  border-radius: 10px;
  margin-top: 50px;
  margin-bottom: 30px;
  padding: 20px 70px;
}

.card-login2 {
  background-color: white;
  width: 100%;
  height: auto;
  min-height: 50vh;
  position: relative;
  border-radius: 10px;
  margin-top: 100px;
  margin-bottom: 30px;
  padding: 20px 70px;
}

.card-login {
  background-image: url(../images/bkg-login.jpg);
  width: 100%;
  height: auto;
  min-height: 50vh;
  position: relative;
  border-radius: 10px;
  margin-top: 140px;
  margin-bottom: 30px;
  padding: 20px;
}

.card-login img {
  position: absolute;
  left: 0;
  bottom: 0;
  max-width: 45%;
  max-height: 100%;
}

.card-registro {
  background-image: url(../images/bkg-register.jpg);
  width: 100%;
  height: auto;
  position: relative;
  border-radius: 10px;
  margin-top: 140px;
  margin-bottom: 30px;
  padding: 20px;
}

.card-registro img {
  position: absolute;
  left: 0;
  bottom: 0;
  max-width: 45%;
  max-height: 100%;
}

.card-recupero {
  background-image: url(../images/bkg-recupero.jpg);
  width: 100%;
  height: auto;
  min-height: 50vh;
  position: relative;
  border-radius: 10px;
  margin-top: 140px;
  margin-bottom: 30px;
  padding: 20px;
}

.card-recupero img {
  position: absolute;
  left: 0;
  bottom: 0;
  max-width: 50%;
  max-height: 100%;
}

.card-api {
  background-image: url(../images/trama-1.jpg);
  width: 100%;
  height: auto;
  min-height: 300px;
  position: relative;
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 20px;
  font-weight: 500;
  color: #fff;
}

.card-api img {
  position: absolute;
  left: -50px;
  top: -50px;
  max-width: 120%;
  max-height: 120%;
}

.card-institucional {
  background-image: url(../images/trama-3.jpg);
  width: 100%;
  height: auto;
  min-height: 300px;
  position: relative;
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 20px;
  font-weight: 500;
  color: #fff;
}

.card-institucional img {
  position: absolute;
  left: 0px;
  bottom: -125px;
  width: 100%;
  height: auto;
}

.card-oai {
  background-image: url(../images/trama-3.jpg);
  width: 100%;
  height: auto;
  min-height: 300px;
  position: relative;
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 20px;
  font-weight: 500;
  color: #fff;
}

.card-oai img {
  position: absolute;
  left: -50px;
  top: -50px;
  max-width: 120%;
  max-height: 120%;
}

.card-rea {
  background-image: url(../images/trama-2.jpg);
  width: 100%;
  height: auto;
  min-height: 350px;
  position: relative;
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 20px;
  font-weight: 500;
  color: #fff;
}

.card-rea img {
  position: absolute;
  left: 0px;
  bottom: -350px;
  width: 90%;
  height: auto;
  margin-left: 5%;
}

.bubble {
  height: auto;
  width: 100%;
  min-width: 200px;
  background: white;
  display: block;
  margin: 30px 0px 0px 0px;
  border-radius: 5px;
  text-align: left;
  padding: 10px;
}

.pointer {
  height: 30px;
  width: 30px;
  background: white;
  margin: 0px 0px -10px 70px;
  transform: rotate(45deg);
  border-radius: 0 0 5px 0;
  margin-top: -15px;
  position: relative;
  left: calc(0.1vw - 50px);
}

.blurred {
  background: gray;
  filter: blur(20px);
  position: relative;
  top: -315px;
  /* 	transform: scale(1.05); */
  z-index: -1;
}

/*Select2*/
.select2-container--default .select2-selection--single {
  border-radius: 0px !important;
  background-color: #f1f1f1 !important;
  border: none !important;
}

.select2-container .select2-selection--single {
  height: 38px !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 38px !important;
  font-family: "Encode Sans", sans-serif !important;
  letter-spacing: 1px !important;
  font-size: 14px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  margin-top: 2px !important;
}

.select2-container--default
  .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #3dcfd0 !important;
}

/*Checkbox*/
/* The container */
.container-ch {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 25px;
  padding-right: 15px;
  font-size: 14px;
  font-family: "Encode Sans", sans-serif !important;
  letter-spacing: 0px;
  font-weight: 500;
  color: #50535c !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.container-ch:hover {
  /* font-weight: bold!important; */
  text-decoration: underline !important;
  /* color: #AAA!important; */
}

/* Hide the browser's default checkbox */
.container-ch input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border: 1px solid #000;
}

/* On mouse-over, add a grey background color */
.container-ch :hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-ch input:checked ~ .checkmark {
  background-color: #34babc;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-ch input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-ch .checkmark:after {
  left: 6px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*JM colors*/
.bg-card {
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.bg-card:hover {
  transform: scale(1.03);
}

.jm-bgk-img {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 0px;
  padding: 20px;
  height: 400px;
}

.jm-bgk-img2 {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 0px;
  padding: 20px;
  height: 350px;
}

.jm-bgk-img3 {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 0px;
  padding: 20px;
  height: 300px;
}

.jm1 {
  background-color: #37bbed;
}
.jm2 {
  background-image: url(../images/trama-2.jpg);
}
.jm3 {
  background-image: url(../images/trama-3.jpg);
}
.jm4 {
  background-image: url(../images/trama-4.jpg);
}
.jm5 {
  background-color: #822b86;
  padding: 10px 0px;
}
.jm6 {
  background-color: #e69732;
  padding: 10px 0px;
}
.jm7 {
  background-color: #1ac2c2;
  padding: 10px 0px;
}
.jm8 {
  background-color: #119d57;
  padding: 10px 0px;
}
.jm9 {
  background-color: #238da5;
  padding: 10px 0px;
}

.JMAulas {
  background-image: url(../images/trama-4.jpg);
}
.JMDatos {
  background-image: url(../images/trama-3.jpg);
}
.JMRecursos {
  background-image: url(../images/trama-2.jpg);
}
.JMDirectores {
  background-image: url(../images/trama-4.jpg);
}
.JMDocentes {
  background-image: url(../images/trama-3.jpg);
}
.JMEstudiantes {
  background-image: url(../images/trama-2.jpg);
}

.max-ch {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
/*Viewpoint - list or gird */
.view-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
}
.thumbnail {
  margin-bottom: 30px;
  padding: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}

.item.list-group-item {
  float: none;
  width: 100%;
  background-color: #fff;
  margin-bottom: 10px;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 1rem;
  border: 0;
  margin-top: 0px;
}
.item.list-group-item .img-event {
  float: left;
  width: 30%;
  position: relative;
  top: 0px;
  padding-bottom: 10px;
}

.item.list-group-item .list-group-image {
  margin-right: 10px;
}
.item.list-group-item .thumbnail {
  margin-bottom: 0px;
  display: inline-block;
}
.item.list-group-item .caption {
  float: left;
  width: 70%;
  margin: 0;
  position: relative;
  top: 0px;
}

.item.list-group-item:before,
.item.list-group-item:after {
  display: table;
  content: " ";
}

.item.list-group-item:after {
  clear: both;
}

.item.list-group-item .card-footer {
  width: 70%;
  float: right;
  top: 0px;
  position: relative;
}

.card-footer2 {
  padding: 10px;
  background-color: transparent;
  position: absolute;
  top: 45%;
  height: 100px;
  margin-top: -50px;
  margin: auto;
  left: 50%;
  width: 100px;
  margin-left: -75px;
}

div .card-tutoriales {
  padding: 15px 0px 0px 15px !important;
  color: #fff !important;
  height: 78px !important;
  margin-bottom: 0px !important;
}

/* --------------- Carousel ----------------*/

.carousel-item {
  height: auto;
}
.carousel-item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: auto;
}
.carousel-control-prev,
.carousel-control-next {
  height: 120px;
}
.slider-thumb {
  position: absolute;
  top: 40px;
  left: 80px;
  height: 320px;
  border-radius: 0px;
  -webkit-box-shadow: 0px 0px 32px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 32px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 32px -4px rgba(0, 0, 0, 0.75);
}
.s1 {
  font-family: "Raleway" sans-serif;
  font-weight: 800 !important;
  font-size: 40px;
  text-transform: uppercase;
}

/*Search button*/
.searchBox {
  display: none;
  position: fixed;
  top: 80px;
  left: 70px;
  transform: translate(-50px, 50px);
  background: #333;
  height: 80px;
  margin-top: 0px;
  padding: 0px;
  border-radius: 10px;
  z-index: 1003;
}

.searchBox:hover > .searchInput {
  width: 300px;
  padding: 0 6px;
  margin-left: 10px;
}

.searchBox:hover > .searchButton {
  background: transparent;
  color: #2f3640;
  background-image: url(../images/icono_zoom.svg);
  background-repeat: no-repeat;
  background-position: left;
  cursor: pointer;
  height: 100px;
  width: 100px;
  margin-top: -15px;
}

.searchButton {
  float: left;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
  background-image: url(../images/icono_jm.svg);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  margin-left: 0px;
  border: 0px !important;
  margin-top: -15px;
  margin-right: -10px;
}

.searchInput {
  border: none;
  background: none;
  outline: none;
  float: right;
  padding: 0;
  color: white;
  font-size: 16px;
  transition: 0.4s;
  line-height: 80px;
  width: 0px;
  margin-left: 0px;
}

.searchButton:focus {
  outline: none !important;
  cursor: pointer;
}

.searchBoxVisible {
  position: fixed;
  top: 80px;
  left: 70px;
  transform: translate(-50px, 50px);
  background: #333;
  height: 80px;
  margin-top: 5px;
  padding: 0px;
  border-radius: 10px;
  z-index: 1003;
}

.searchBoxVisible:hover > .searchInput {
  width: 300px;
  padding: 0 6px;
  margin-left: 10px;
}

.searchBoxVisible:hover > .searchButton {
  background: transparent;
  color: #2f3640;
  background-image: url(../images/icono_zoom.svg);
  background-repeat: no-repeat;
  background-position: left;
  cursor: pointer;
  height: 100px;
  width: 100px;
  margin-top: -15px;
}

.searchButton {
  float: left;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
  background-image: url(../images/icono_jm.svg);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  margin-left: 0px;
  border: 0px !important;
  margin-top: -15px;
  margin-right: -10px;
}

.searchInput {
  border: none;
  background: none;
  outline: none;
  float: right;
  padding: 0;
  color: white;
  font-size: 16px;
  transition: 0.4s;
  line-height: 80px;
  width: 0px;
  margin-left: 0px;
}

.searchButton:focus {
  outline: none !important;
  cursor: pointer;
}

/* Button - Ayuda */
.floating_btn {
  position: fixed;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  bottom: 40px;
  right: 40px;
  background-color: #ffea00;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 1s transform cubic-bezier(0.155, 1.105, 0.295, 1), 0.5s box-shadow,
    2s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1), 0.5s,
    border-radius 0.5s;
  background-image: url(../images/icono_jm.svg);
  background-repeat: no-repeat;
  background-position: right;
  cursor: pointer;
  z-index: 1001;
}

.floating_btn:hover {
  background-color: #333;
  border-radius: 5px;
  width: 250px;
}

.floating_text {
  color: transparent;
  font-size: 16px;
  position: relative;
  top: 50%;
  left: 40%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: left;
  padding-top: 20px;
  padding-bottom: 20px;
}

.floating_text:hover {
  color: white;
  padding-right: 150px;
  padding-left: 30px;
  margin-left: 15px;
}

.logo-jm {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
}
main > .container {
  padding: 120px 15px 0;
  height: auto;
}

.table {
  color: #000;
}

/*Footer*/
.footer {
  background-color: #2eb5e6;
  min-height: 160px;
  margin-top: 50px;
  padding-bottom: 0px !important;
}

.footer > .container {
  padding: 15px 15px 20px 15px;
}

.legales {
  background-color: #fff !important;
  color: #333;
  padding: 15px 0px;
  line-height: 15px;
  margin-top: 29px;
}

code {
  font-size: 80%;
}

.logo-left {
  float: right;
  margin-left: auto;
  position: absolute;
  right: 20px;
}

.text-red {
  color: #e40f5c !important;
}

.text-blue {
  color: #0072bb !important;
}

.text-teal {
  color: #37bbed !important;
}

.text-dark-grey {
  color: #50535c !important;
}

a.text-blue:hover {
  color: #26b9ec !important;
}

.text-lime {
  color: #d2e23e !important;
}

a.text-lime:hover {
  color: #b1bd40 !important;
}

.bg-lime {
  background-color: #d2e23e !important;
}

.text-orange {
  color: #fc9700;
}

a.text-orange:hover {
  color: #fc5d00 !important;
}

.bg-orange {
  background-color: #fc9700 !important;
}

.separator {
  height: 3px;
  background-color: #26b9ec;
  border-radius: 5px;
  max-width: 100px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.bg-title {
  background-color: #26b9ec !important;
  color: #fff;
  width: 100%;
  padding: 7px 10px 10px 10px;
  margin: 0px;
}

.table th,
.table td {
  padding: 0.5rem;
  font-size: 14px;
}

div#accordion {
  width: 100%;
}

.panel-heading {
  background-color: #2dc3ee;
  color: #fff;
  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid #fff;
  padding-right: 20px;
}

.panel-heading:hover {
  background-color: #2eafd4;
}

.panel-body {
  width: 100%;
  padding: 20px;
}

.panel-title {
  position: relative;
  padding: 12px 20px 8px 20px;
}

.panel-title::after {
  content: "\f107";
  color: #fff;
  top: 14px;
  right: 0px;
  position: absolute;
  font-family: "FontAwesome";
}

.panel-title[aria-expanded="true"]::after {
  content: "\f106";
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.navbar {
  padding: 15px 10px;
  background: #333;
  border: none;
  border-radius: 5px;
  margin-bottom: 40px;
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

.navbar.dark {
  background-color: #2eb5e6;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
  perspective: 1500px;
  margin-top: 110px;
}

#sidebar {
  background: #f9fafb;
  color: #333;
  transition: all 0.6s cubic-bezier(0.945, 0.02, 0.27, 0.665);
  transform-origin: bottom left;
}

#sidebar.active {
  margin: 0 150px 0 -250px;
}

#sidebar .sidebar-header {
  padding: 26px 0px 27px 20px;
  background: #f9fafb;
  border-bottom: 1px solid #dcdcdc;
  margin-top: 30px;
}

#sidebar .sidebar-body {
  padding: 20px;
  background: #f9fafb;
  border-bottom: 1px solid #dcdcdc;
}

.sidebar-body {
  font-size: 15px;
  font-weight: 400;
}

#sidebar ul.components {
  padding: 0px;
}

#sidebar ul p {
  color: #fff;
  padding: 10px;
}

#sidebar ul li a {
  padding: 10px;
  display: block;
  border-bottom: 1px solid #f1f1f1;
  font-family: "Encode Sans", sans-serif !important;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 1px;
  color: #3d3d3d;
  background-color: #e0e0e0;
}
#sidebar ul li a:hover {
  color: #333;
  background: #d4d4d4;
}

#sidebar ul li.active > a,
a[aria-expanded="true"] {
  color: #333;
  background: #f9fafb;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #f9fafb;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: #7386d5;
}

a.article,
a.article:hover {
  background: #6d7fcc !important;
  color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
#content2 {
  width: 100%;
  padding: 20px;
  min-height: 100vh;
  transition: all 0.6s;
  overflow: hidden;
}

#content {
  width: 100%;
  padding: 45px;
  min-height: 100vh;
  transition: all 0.6s cubic-bezier(0.81, -0.33, 0.345, 1.375);
  overflow: hidden;
}

.content-body {
  font-size: 15px;
  font-weight: 400;
}

#sidebarCollapse {
  width: 40px;
  height: 40px;
  background: #e1dfdf;
  cursor: pointer;
}

#sidebarCollapse svg{
  transform:scaleX(-1);
  transition: transform 1s;}
#sidebarCollapse.active svg{
  transform:scaleX(1);
  transition: transform 1s;}
@media screen and (max-width: 768px) {
  #sidebarCollapse svg{transform:scaleX(1);}
  #sidebarCollapse.active svg{transform:scaleX(-1);}
}

/* #region flecha hernan */

#sidebarCollapse span {
  width: 45%;
  height: 2px;
  /* margin: 0 auto; */
  display: block;
  background: #555;
  transition: all 0.6s cubic-bezier(0.81, -0.33, 0.345, 1.375);
  transition-delay: 0.2s;
}

#sidebarCollapse span:first-of-type {
  transform: rotate(45deg) translate(7px);
}
/* #sidebarCollapse span:nth-of-type(2) {
  opacity: 0;
} */
#sidebarCollapse span:last-of-type {
  transform: rotate(-45deg) translate(7px);
}

/* #sidebarCollapse.active span {
  transform: none;
  opacity: 1;
  margin: 5px auto;
} */

#sidebarCollapse.active span:first-of-type {
  transform: rotate(135deg) translate(7px);
  margin-left: 13px;
}

#sidebarCollapse.active span:last-of-type {
  transform: rotate(-135deg) translate(7px);
  margin-left: 13px;
}
/* #endregion */

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
@media screen and (max-width: 768px) {
  .hidden-xs {
    display: none !important;
  }
  .main-img {
    padding: 50px 20px 30px 20px !important;
    margin-bottom: 40px;
    margin-top: 70px;
  }
  .s1 {
    font-size: 22px !important;
    line-height: 24px !important;
  }
  .footer {
    /* max-height: 200px;  */
    padding: 0px !important;
    min-height: 230px !important;
  }
  .legales {
    font-size: 12px;
    margin-top: 20px;
  }
  body {
    font-size: 0.9rem;
  }
  h1 {
    font-size: 23px;
  }
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 20px;
  }
  #content {
    width: 100%;
    /* padding: 0px; */
    min-height: 100vh;
    transition: all 0.6s cubic-bezier(0.945, 0.02, 0.27, 0.665);;
  }
  #content.collapsed {
    margin-left: -250px;
  }
  .jm1 {
    height: 300px !important;
    margin-bottom: 20px;
    margin-top: 0px !important;
    padding: 0px !important;
  }
  .jm2 {
    height: 200px !important;
    margin-bottom: 20px;
    margin-top: 0px !important;
  }
  .jm3 {
    height: 300px !important;
    margin-bottom: 20px;
    margin-top: 0px !important;
    padding: 0px !important;
  }
  .jm4 {
    height: 300px !important;
    margin-bottom: 20px;
    margin-top: 0px !important;
    padding: 0px !important;
  }
  .jm5 {
    height: 300px !important;
    margin-bottom: 20px;
    margin-top: 0px !important;
    padding: 0px !important;
  }
  .jm6 {
    height: 300px !important;
    margin-bottom: 20px;
    margin-top: 0px !important;
    padding: 0px !important;
  }
  .jm7 {
    height: 300px !important;
    margin-bottom: 20px;
    margin-top: 0px !important;
    padding: 0px !important;
  }
  .jm8 {
    height: 300px !important;
    margin-bottom: 20px;
    margin-top: 0px !important;
    padding: 0px !important;
  }
  .jm9 {
    height: 300px !important;
    margin-bottom: 20px;
    margin-top: 0px !important;
    padding: 0px !important;
  }
  .jm_buscador {
    position: relative;
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-top: auto;
  }
  .max-ch {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  #content {
    margin-top: 20px;
  }
  .logo-educar {
    padding-left: 20px;
    width: 100%;
  }
  .logo-jm {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
  }
  .logos-footer {
    width: 100% !important;
    height: auto;
    position: relative !important;
    padding-left: 0%;
    padding-right: 0%;
  }

  h2 {
    font-size: 20px;
    margin-top: 0px;
    line-height: 26px;
  }
  h3 {
    font-size: 16px;
    margin-top: 0px;
  }
  h5 {
    font-size: 18px;
  }
  h6 {
    line-height: 22px;
  }
  .lh-20 {
    line-height: 20px !important;
  }
  .lh-30 {
    line-height: 22px !important;
  }
  .lead {
    font-size: 15px;
  }
  .display-5 {
    font-size: 2rem;
    line-height: 1.2;
  }

  .input-group-lg > .form-control,
  .input-group-lg > .custom-select,
  .input-group-lg > .input-group-prepend > .input-group-text,
  .input-group-lg > .input-group-append > .input-group-text,
  .input-group-lg > .input-group-prepend > .btn,
  .input-group-lg > .input-group-append > .btn {
    font-size: 13px;
    padding: 6px;
    max-height: 40px;
  }
  .slider-thumb {
    display: none;
  }
  .bubble {
    padding: 4px;
  }
  .pointer {
    width: 20px !important;
    height: 20px !important;
    margin-top: -10px;
  }
  .btn-outline-primary {
    padding: 0px 10px !important;
    font-size: 20px !important;
  }
  .floating_btn {
    top: 87% !important;
    right: 20px;
  }
  .jm-buscador {
    margin-top: 95px;
    width: 90%;
    left: 5%;
  }

  .panel-group {
    padding: 0px 20px;
  }
  .panel-title {
    font-size: 16px;
    padding-right: 40px;
  }
  .panel-body {
    font-size: 14px;
  }

  .table {
    font-size: 0.9rem;
  }

  .navbar {
    margin-bottom: 0px;
    max-width: 100vw;
  }
  /* .navbar-collapse {background-color: #333;border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;margin-left: 0px;margin-right: 0px;padding-right: 20px;padding-left: 20px;margin-top: -5px;line-height: 40px;padding-bottom: 20px;} */
  .navbar {
    margin-top: 0px;
    max-width: 100%;
  }

  .searchBox {
    display: none;
    position: fixed;
    top: 55px;
    left: 60px;
    transform: translate(-50px, 50px);
    background: #333;
    height: 60px;
    margin-top: 0px;
    padding: 0px;
    border-radius: 10px;
    z-index: 1003;
  }
  .searchBox:hover > .searchInput {
    min-width: 330px;
    padding-left: 70px;
    margin-left: 20px;
  }
  .searchBox:hover > .searchButton {
    background: transparent;
    color: #2f3640;
    background-image: url(../images/icono_zoom.svg);
    background-repeat: no-repeat;
    background-position: left;
    cursor: pointer;
    height: 80px;
    width: 80px;
    margin-top: -70px !important;
    position: relative;
    z-index: 1004;
  }
  .searchButton {
    float: left;
    width: 80px !important;
    height: 80px !important;
    border-radius: 10px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
    background-image: url(../images/icono_jm.svg);
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    margin-left: 0px;
    border: 0px !important;
    margin-top: -10px !important;
    margin-right: -10px;
  }
  .searchInput {
    border: none;
    background: none;
    outline: none;
    float: right;
    padding: 0;
    color: white;
    font-size: 14px;
    transition: 0.4s;
    line-height: 60px !important;
    width: 0px;
    margin-left: 0px;
  }
  .searchButton:focus {
    outline: none !important;
    cursor: pointer;
  }
  .searchBoxVisible:hover > .searchInput {
    width: 100px;
    padding: 0 6px;
  }

  .searchResultBox {
    display: none !important;
  }

  .card-title {
    font-size: 18px;
  }
  .card-search {
    padding: 20px;
    height: 360px;
  }
  .card-body.card-lg {
    margin-top: 165px;
  }
  .card-text:last-child {
    margin-top: 0px !important;
  }
  .carousel-inner {
    max-height: 360px;
  }
  .carousel {
    margin-top: 40px;
  }
  .carousel-caption {
    margin-left: 20px;
    bottom: 0px;
  }
  .carousel-control-prev-icon.dark {
    background-image: url(../images/arrow_left-dark.svg);
    margin-left: 0px;
  }
  .carousel-control-next-icon.dark {
    background-image: url(../images/arrow_right-dark.svg);
    margin-right: 0px;
  }

  .icono-sm {
    background-position: center center;
    background-size: contain;
    height: 120px;
    width: 120px;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 0px;
    margin-bottom: 20px;
  }
  .icono {
    height: 60px;
    width: 60px;
  }
  .icono-buscador {
    width: 50px;
    height: 50px;
  }
  .icono-home {
    width: 150px;
    height: 150px;
  }
  .icono-home-lg {
    height: 120px;
    width: 120px;
  }
  .icono-home-xl {
    height: 130px;
    width: 130px;
  }

  #sidebarCollapse {
    z-index: 1;
    transform: scale(0.75);
  }
  #sidebar {
    margin-left: -250px;
    /* transform: rotateY(90deg); */
    min-width: 250px;
    z-index: 1;
  }
  #sidebar.active {
    margin-left: 0;
    margin-right: 0;
    z-index: 1;
    /* transform: none; */
    /* margin-right: 20%; */
  }
  #sidebarCollapse span:first-of-type {
    transform: rotate(45deg) translate(-1px);
    margin: 5px 10px;
  }

  #sidebarCollapse span:last-of-type {
    transform: rotate(-45deg) translate(-1px);
    margin: 5px 10px;
  }

  #sidebarCollapse.active span {
    margin: 0 auto;
  }
  #sidebarCollapse.active span:first-of-type {
    transform: rotate(135deg) translate(-4px);
    margin-left: 4px;
  }
  #sidebarCollapse.active span:last-of-type {
    transform: rotate(-135deg) translate(-4px);
    margin-left: 4px;
  }

  #sidebar .sidebar-header {
    padding: 26px 20px;
  }
  .wrapper {
    margin-top: 90px;
    overflow: hidden;
  }
  .container-ch {
    font-size: 12px;
    letter-spacing: 0px;
    line-height: 24px;
  }
  .checkmark {
    top: 2px;
  }

  .card-registro img {
    display: none;
  }
  .card-login img {
    display: none;
  }
  .card-recupero img {
    display: none;
  }

  .tech_logo {
    width: auto;
    height: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  .tab_buttons {
    width: 50%;
  }

  .card-header2 {
    left: 0px !important;
    top: 0px !important;
    margin-bottom: 0px;
  }
  .breadcrumb {
    margin-top: -20px;
    margin-left: -10px;
    margin-bottom: 20px;
  }


  .footerDatos {
    text-align: center;
  }

  .cool-link {
    color: #666;
    display: inline-block;
    margin: 0;
    text-transform: uppercase;
  }

  .cool-link:after {
    display: block;
    content: "";
    border-bottom: solid 2px #fff;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }
  .cool-link:hover:after {
    transform: scaleX(1);
  }
  .cool-link.fromRight:after {
    transform-origin: 100% 50%;
  }
  .cool-link.fromLeft:after {
    transform-origin: 0% 50%;
  }

  li.nav-item.active:after {
    border-bottom: 2px solid #fff;
    content: "";
    display: block;
    margin: -10px auto;
    width: 50%;
    padding-top: 0px;
    margin-bottom: 10px;
    left: 30px;
    position: absolute;
  }
  .textHighlight {
    text-align: center;
  }
  #content2 {
    width: 100%;
    padding: 0px;
    min-height: 100vh;
    transition: all 0.3s;
    margin-left: -50px;
    margin-top: 25px;
  }

  .ver-completo-btn {
    height:54px;
    width:150px;
  }

  nav.navbar.navbar-expand-lg.navbar-dark.dark.fixed-top {
    margin-top: -20px;
  }
  .icon-bar {
    margin-left: 8% !important;
  }
  .icon-bar.detail {
    margin-left: 8% !important;
  }
}

/* ------- Recursos List ----------- */

.treeview-animated.w-20 {
  width: 20rem;
  font-size: 14px;
}

.treeview-animated h6 {
  font-size: 1.4em;
}

.treeview-animated ul {
  position: relative;
  list-style: none;
  padding-left: 0;
}

.treeview-animated-list li {
  padding: 0.2em 0 0 0.2em;
}

.treeview-animated-element {
  padding: 10px;
  cursor: pointer;
  transition: all 0.1s linear;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  border: none;
}

.treeview-animated-element:hover {
  color: #3e454b;
  background-color: #f8f9fa;
  border-left: 4px solid #1bcaca;
  padding-left: 15px;
  transition: 0.2s;
}

.treeview-animated-element.opened {
  color: #f8f9fa;
  background-color: #1bcaca;
  padding-left: 6px;
}

.treeview-animated-element.opened:hover {
  color: #f8f9fa;
  background-color: #1bcaca;
}

.treeview-animated-items .nested::before {
  content: "";
  display: block;
  position: absolute;
  background-color: #e5e8eb;
  left: 0px;
  width: 1px;
  height: 100%;
}

.treeview-animated-items .closed {
  display: block;
  padding: 10px;
  margin-right: 0;
  border-top-right-radius: 0.3em;
  border-bottom-right-radius: 0.3em;
}

.treeview-animated-items .closed:hover {
  color: #3e454b;
  background-color: #f8f9fa;
  border-left: 4px solid #1bcaca;
  padding-left: 15px;
  transition: 0.2s;
}

.treeview-animated-items .open {
  transition: all 0.1s linear;
  background-color: #1bcaca;
}

.treeview-animated-items .open span {
  color: #f8f9fa;
}

.treeview-animated-items .open:hover {
  color: #fff;
  background-color: #1bcaca;
}

.treeview-animated ul li .open div:hover {
  background-color: rgb(50, 160, 255);
}

.treeview-animated-items .closed .fa-angle-right {
  transition: all 0.1s linear;
  font-size: 0.8rem;
}

.closed .fa-angle-right.down {
  position: relative;
  color: #f8f9fa;
  transform: rotate(90deg);
}
.font30 {
  font-size: 30px !important;
  -webkit-text-stroke: 2px white;
}

.closeList {
  font-size: 25px !important;
  -webkit-text-stroke: 4px white;
  margin-top: 16px;
  margin-right: 10px;
  color: #141414;
}

.font18 {
  font-size: 18px !important;
}

.btn-link {
  font-weight: 400;
  color: #34babc;
  text-decoration: none;
}

.dropdownListados {
  display: inline-block;
  width: 100%;
  height: calc(2em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000000;
  border: 1px solid #141414;
  border-radius: 0.3rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-align: left;
  background: #fff;
}

.lh-36 {
  line-height: 36px;
}

.sortable-chosen {
  background-color: #f9f9f9;
}

.deleteListItem {
  position: relative;
  left: 180px;
  top: -40px;
}

.recurso-link:hover {
  text-decoration: underline;
  /* font-weight: bold; */
}

.sideNavs {
  margin: 10px 0px 10px 0px;
  border-radius: 0px !important;
  /* transition: 0.4s; */
  color: #50535c !important;
  padding: 0px 10px;
  border-top: none !important;
  border-right: none !important;
  border-bottom: none !important;
  border-left: 3px solid #fff !important;
  line-height: 18px;
  font-size: 15px !important;
  font-weight: 500;
}

.sideNavs:hover {
  border-left: 3px solid #2fb5e6 !important;
  border-bottom: none !important;
  margin-left: 10px;
  border-radius: 0px !important;
  /* transition: 0.4s; */
  color: #34babc;
}

.sideNavs.active {
  border-left: 3px solid #2fb5e6 !important;
  border-bottom: none !important;
  margin-left: 10px;
  border-radius: 0px !important;
  /* transition: 0.4s; */
  color: #0072bb !important;
}

.sideNavs.active:hover {
  color: #37bbed !important;
}

.nav-tabs > li:hover > span {
  display: block;
}

.list-group-item {
  border-radius: 0px !important;
}

.mt-25 {
  margin-top: 12px;
}

.editInput {
  overflow: visible !important;
  background: #edf1f5 !important;
  top: 0px !important;
  position: relative !important;
  margin-top: 5px !important;
  border: none !important;
  margin-left: 10px !important;
  padding: 5px 0px 5px 10px !important;
  border-radius: 5px !important;
  max-width: 190px !important;
}

#alert_TooltipOne {
  background-color: #903693;
  position: absolute;
  top: -42px;
  width: 330px;
  left: 250px;
  line-height: 24px;
  font-size: 15px !important;
  text-align: left;
  border: none;
  padding: 20px !important;
  z-index: 1050;
}

#alert_TooltipOne:before {
  content: "";
  position: absolute;
  top: calc(50% - 12px);
  left: -30px;
  border-style: solid;
  border-width: 25px 25px 0;
  border-color: #903693 transparent;
  display: block;
  width: 0;
  z-index: 1;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}
#alert_TooltipTwo {
  background-color: #903693;
  position: absolute;
  top: -130px;
  width: 350px;
  margin-left: 0;
  z-index: 1050;
  line-height: 24px;
  font-size: 15px !important;
  text-align: left;
  border: none;
  padding: 20px !important;
  right: 40%;
}
#alert_TooltipTwo:before {
  content: "";
  position: absolute;
  bottom: -15px;
  left: calc(50% - 25px);
  border-style: solid;
  border-width: 25px 25px 0;
  border-color: #903693 transparent;
  display: block;
  width: 0;
  z-index: 1;
}

.alertClose {
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  transition: 0.5s;
}
.alertClose:hover {
  color: #fff;
  opacity: 0.5;
  text-decoration: none;
  transition: 0.5s;
}

.alert-dark {
  color: #ffffff;
  background-color: #ee3d8f;
  border-color: #ee3d8f;
  font-family: "Encode Sans", sans-serif !important;
}

#alert-fadein {
  animation: fadein 2s;
  -moz-animation: fadein 2s; /* Firefox */
  -webkit-animation: fadein 2s; /* Safari and Chrome */
  -o-animation: fadein 2s; /* Opera */
  padding: 5px 45px 5px 45px;
  border-radius: 5px;
  position: absolute;
  top: -25px; /*-80px*/
  text-align: center;
  font-weight: 300;
  width: 100%;
  min-height: 48px;
  vertical-align: middle;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.alert {
  position: relative;
}

.alert {
  padding: 0.56rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 13px !important;
  font-weight: 300;
  line-height: 16px;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.8125rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.35rem 1rem;
  color: inherit;
}
.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    -webkit-transition: none;
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.fade.in {
  opacity: 1;
}

.img-event .card-img-top {
  height: 90px !important;
}

.body-resource-height .badge {
  cursor: pointer;
}

.filter-active {
  padding: 4px 14px;
}

.blue {
  color: #1bcaca;
}

.icon-bar {
  margin-left: 4%;
}

.icon-bar.detail {
  margin-left: 3%;
}

.recursos {
  display: grid;
  grid-template-columns: repeat(3, 380px);
  /* grid-template-rows: repeat(3, 1fr); */
  row-gap: 20px;
  column-gap: 20px;
  margin: 0 auto;
  justify-content: space-evenly;
}

.recursos-items {
  width: 380px;
  justify-self: center;
}

.container-tjt {
  width: 100%;
  height: 535px;
  margin: 0 auto;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-in-out;
  position: relative;
}

.container-tjt:hover {
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.35);
}

.tjt-pin {
  top: -12px;
  right: -10px;
  position: absolute;
}

.tjt-img-container {
  height: 170px;
}

.tjt-img {
  background-color: #0072bb;
  width: 100%;
  height: 170px;
  object-fit: cover;
  /* object-position: 50px; */
}

.tjt-cuerpo {
  padding: 20px;
}

.tjt-propuesta,
.tjt-etiqueta {
  font-weight: 600;
  color: #0072bb;
  transition: all 0.5s;
}

.tjt-propuesta {
  font-size: 0.938em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tjt-propuesta:hover,
.tjt-etiqueta-item:hover {
  cursor: pointer;
  color: #37bbed;
  text-decoration: underline;
}

.tjt-link:hover {
  text-decoration: none;
}

.tjt-titulos {
  margin-bottom: 20px;
}

.tjt-title {
  color: #000;
  height: 60px;
  overflow: hidden;
  font-size: 1.25em;
  font-weight: inherit;
  text-transform: none;
}

.tjt-title:hover {
  text-decoration: underline;
}

.tjt-title > p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.tjt-subtitle {
  font-size: 0.938em;
  font-weight: 700;
}

.tjt-etiqueta {
  font-size: 0.688em;
  display: inline-block;
  font-weight: 400;
  margin-bottom: 15px;
  height: 51px;
  overflow: hidden;
  width: 100%;
}

.tjt-etiqueta-container {
  display: flex;
  flex-wrap: wrap;
}

.tjt-copete {
  /* height: 100px; */
  height: 85px;
  font-weight: 400;
  font-size: 1.063em;
  margin-bottom: 20px;
  overflow: hidden;
}

.tjt-copete-txt {
  height: 85px;
  overflow: hidden;
}

.tjt-iconos {
  position: absolute;
  bottom: 15px;
}

.side-bar {
  margin-left: 6.25em;
}

/* .side-bar {
    height: 805px;
  } */

.side-bar-container {
  width: 250px;
}

.btn-menu {
  position: absolute;
  top: 15px;
  /* left: 285px; */
  left: 335px;
  border-radius: 50%;
  transition: all 0.6s cubic-bezier(0.945, 0.02, 0.27, 0.665);
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.15);
}

.btn-menu:hover {
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.25);
}

.btn-menu.active {
  left: 50px;
}

#breadCrumb {
  margin-top: 20px;
}
.breadcrumb {
  max-height: 25px;
  flex-wrap: nowrap;
}
.breadcrumb-item.active {
  display: inline;
  width: 60%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sidebar-body .recurso-item {
  color: #000;
  font-weight: 400;
}

.sidebar-body .recurso-item:hover {
  color: #000;
}

.licencia img {
  width: 60%;
}

.btn-descarga {
  background-color: #0072bb;
  color: #fff;
  border-radius: 5px;
}

.btn-descarga:hover {
  background-color: #2eb5e6;
  color: #fff;
  border-radius: 5px;
}

.btn-season {
  width: 100%;
  margin-top: 1rem;
  background-color: #e1dfdf;
  border: 1px solid transparent;
}

.btn-season:focus {
  outline: none;
}

.btn-season-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-season-items p {
  margin: none;
}

.btn-season-open-container {
  border: none;
  background-color: transparent;
}

.btn-season-open-container:focus {
  outline: none;
}

.btn-season-open {
  width: 40px;
  height: 40px;
  background-color: #2EB5E6;
  cursor: pointer;
  border-radius: 50%;
  border: none;
  transform: rotate(-90deg);
  stroke-width: 10
}

.btn-season-open-container.active .btn-season-open{
  transform: rotate(90deg);
}


.btn-season-open:focus {
  outline: none;
}

.btn-season-open span {
    width: 45%;
    height: 2px;
    /* margin: 0 auto; */
    display: block;
    background: #fff;
    transition: all 0.6s cubic-bezier(0.81, -0.33, 0.345, 1.375);
    transition-delay: 0.2s;
}

.btn-season-open div {
   margin-top: 5px;
   /* transition: all 0.6s cubic-bezier(0.81, -0.33, 0.345, 1.375); */
}

/* .btn-season-open.active div {
   margin-top: -1px;
} */

.btn-season-open span:first-of-type {
    transform: rotate(45deg);
    margin: 0px 4px;
}

.btn-season-open span:last-of-type {
    transform: rotate(135deg) translate(-4px);
    margin: 1px 9px;
}

.btn-season-open.active span:first-of-type {
  transform: rotate(-45deg) translate(-4px);
  margin-left: 6.5px;
}
.btn-season-open.active span:last-of-type {
  transform: rotate(225deg);
  margin-left: 11.5px;
  margin-bottom: 7px;
}

.svg-arrow-collapse {
  stroke: #fff;
}

.btn-episode {
  width: 100%;
  background-color: #fff;
  border: 1px solid transparent;
  padding: 0px;
  transition: all 0.3s cubic-bezier(0.81, -0.33, 0.345, 1.375);
}

.btn-episode:focus, .btn-episode:hover {
  background-color: #37bbed;
  outline: none;
}

.flecha-container {
  width: 5%;
  margin-left: 25px;
}

.primero, .segundo {
  background: #0072bb;
  width: 30%;
  height: 2px;
  display: block;
  margin-left: 40px;
}

.btn-details-container:hover .primero, .btn-details-container:hover .segundo {
  background: #fff;
}

.btn-details-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s cubic-bezier(0.81, -0.33, 0.345, 1.375);
}

.btn-details-container:hover {
  text-decoration: none;
  color: #fff;
}

.episode-details {
  height: 66px;
  display: flex;
  align-items: center;
  /* gap: 20px; */
  width: 100%;
  overflow: hidden;
}

.episode-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.episode-details p{
  margin: none;
}

.primero:hover .segundo:hover {
  background: #fff;
}

.primero {
  transform: rotate(45deg) translate(-6px, -1px);
}

.segundo {
  transform: rotate(-45deg) translate(-6px, 1px);
}

.btn-episode.selected {
  background-color: #37bbed;
  /* transition: all 0.5s cubic-bezier(0.81, -0.33, 0.345, 1.375); */
}

.btn-episode.selected .btn-details-container {
  color: #fff;
}

.btn-episode.selected .svg-arrow-episode {
  stroke: #fff;
}

.btn-episode.selected .primero, .btn-episode.selected .segundo {
  background: #fff;
  /* transition: all 0.5s cubic-bezier(0.81, -0.33, 0.345, 1.375); */
}

.btn-episode.collapsed{
  display: none;
}

.sin-informacion {
  color: #fff;
}

.contenedor-etiquetas{
  display: flex;
  flex-wrap: wrap;
}

.detalle-propuesta {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.svg-container{
  margin-right: 25px;
}

.svg-img{
  height: 30px;
  width: 30px;
}

.vidGallery {
  display: none;
}

.texto-truncate--2 > div > span {
  -webkit-line-clamp: 2 !important;
}

.svg-arrow-episode {
  stroke: #000;
  transition: all 0.2s cubic-bezier(0.81, -0.33, 0.345, 1.375);
}

.btn-details-container:hover .svg-arrow-episode {
  stroke: #fff;
}

.espacio-minutos {
  margin-right: 35px;
}

.episode-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 500px;
  text-align: left;
}

.reproductor-panel {
  height: 82px;
  background-color: #ECE7E7;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.audio-btn-container {
  display: flex;
  gap: 55px;
  padding-right: 15px;
}

.audio-btn {
  height: 67px;
  border: none;
  border-radius: 50%;
  background-color: #2EB5E6;
}
.audio-btn:disabled {background-color: #999; opacity: 0.4;}

.audio-btn-transcript {
  border: none;
  background-color: #ece7e700;
}

.audio-btn-transcript>p {
  display: none;
}

.audio-btn-transcript:focus {
  outline: none;
}

.audio-btn:focus {
  outline: none;
  /* border-radius: 50%; */
}

.audio-btn.active {
  background-color: #7FD9F9;
}

.back-container{
  display: flex;
  justify-content: flex-end;
}

.btn-back-col {
  width: 237px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn.btn-back-col {
  font-weight: 300;
}

@media screen and (max-width: 1024px) {
  /* .btn-menu {
    left: 285px;
  } */

  .recursos {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  /* .side-bar-container {
    width: 200px;
  } */

  .side-bar {
    height: 100%;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1401px) {
  /* .btn-menu {
    left: 275px;
  } */
  .btn-menu.active {
    left: 50px;
  }

  .recursos {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .recursos-items {
    width: 300px;
  }
}

@media screen and (min-width: 1402px) and (max-width: 1600px) {
  .recursos {
    grid-template-columns: repeat(3, 280px);
    column-gap: 20px;
  }

  .recursos-items {
    width: 320px;
  }
}

@media screen and (max-width: 768px) {
  .recursos {
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
  }

  .btn-menu {
    top: 76px;
    left: 0.6%;
  }

  .btn-menu.active {
    left: 228px;
  }

  .recursos-items {
    width: 330px;
  }

  .input-group-append {
    display:none;
  }

  .tjt-etiqueta {
    height: 45px;
  }

  .btn-group-lg>.btn, .btn-lg {
    font-size: 1rem;
  }

  #sidebar .sidebar-header {
    margin-top: 0px;
    padding: 22px 20px;
  }

  #sidebar .sb-h-recurso, #sidebar .sb-b-recurso {
    padding-left: 35px;
  }

  .licencia img {
    width: 90%;
  }

  .flecha-container {
    width: 35px;
    margin-left: 0px;
  }

  .flecha-container svg {
      height: 25px;
  }

  .btn-season-open {
    width: 30px;
    height: 30px;
  }

  .espacio-minutos {
    margin-right: 5px;
  }

  .episode-title {
    width: 150px;
  }

  .audio-btn {
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .audio-btn>img {
    height: 40px;
  }

  .audio-btn-transcript {
    height: 58px;
    background-color: #ECE7E7;
    border-radius: 10px;
    margin: 0 auto;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 3px;
  }

  .audio-btn-transcript>p {
    display: block;
    margin-bottom: 0px;
  }

  .btn-back-col {
    width: 180px;
    /* height: 42px; */
    font-size: 12px;
    padding: 8px 10px;
  }
}

/* .recursos {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  } */


@media (max-width: 1401px) {
  .recursos-items {
    /* margin: 20px; */
    width: calc(33% - 20px);
  }
}

@media (max-width: 1199px) {
.recursos-items {
    /* margin: 20px; */
    width: calc(50% - 40px);
}

}

@media (max-width: 991px) {
.recursos-items {
    /* margin: 20px; */
    width: calc(100% - 40px);
}

}

/* ---------------------------------------------------
    AUDIO PLAYER
----------------------------------------------------- */

.component-container {
  margin-top: 150px;
}

/* ---------------------------------------------------
    ESTILO COLECCIONES
----------------------------------------------------- */

.component-container {
    margin-top: 0px;
}

.collection-container {
    background-color: rgb(0 0 0 / 10%);
    padding: 30px;
}

.collection-title h2 {
    text-transform: none;
    font-weight: 400;
    line-height: 1.25em;
}

.collection-title img {
  margin-bottom: 50px;
}

.collection-image {
  width: 100%;
}

.collection-copete {
  margin-top: 50px;
  overflow-wrap: break-word;
}

.collection-copete p {
    font-size: 16px;
}

.noticias-grid .row div {
    min-height: 100%;
}

.noticias-grid {
    padding: 20px;
}

.noticias-grid h4 {
    font-weight: 400;
    line-height: 1.5rem;
    font-size: 20px;
}

.noticias-grid p {
    font-size: 16px;
}

.noticias-grid .card-body img {
    width: 100%;
    height: auto;
}

.ficha-container-grid {
    border: 1px solid #000;
    border-radius: 10px;
}

h3.titulo-ficha {
    border-bottom: 1px solid #000;
    margin-top: 10px;
    padding: 20px;
}

.ficha-publicacion-container {
    display: flex;
    border-bottom: 1px solid #000;
    padding: 10px;
}

p.publicacion-title {
    margin-bottom: 0px;
    font-size: 12px;
}

p.publicacion-fecha {
    font-size: 15px;
}

.ficha-detalle-container {
    margin-top: 20px;
    padding: 20px;
}

p.detalle-title {
    font-size: 12px;
    margin-bottom: 0px;
}

a.detalle-link {
    color: #0072BB;
    font-size: 15px;
}

.detalle-data {
  hyphens: auto;
}
.detalle-data img {
  width: 100%;
}

.iconos-container {
  display: flex;
  align-items: center;
  padding: 0 2.5px;
}

.iconos-container img {
  width: 30px;
  height: 30px;
}

.btn-not-found {
  color: #fff!important;
    background-color: #8f3793;
    border-color: #8f3793;
}

.btn-not-found:hover {
  color: #fff!important;
  background-color: #5d348e;
  border-color: #5d348e;
}

.btn-not-found:focus, .btn-not-found.focus {
  color: #fff!important;
  background-color: #5d348e;
  border-color: #5d348e;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-not-found.disabled, .btn-not-found:disabled {
  color: #fff!important;
  background-color: #8f3793;
  border-color: #8f3793;
}

.btn-not-found:not(:disabled):not(.disabled):active, .btn-not-found:not(:disabled):not(.disabled).active,
.show > .btn-not-found.dropdown-toggle {
  color: #fff!important;
  background-color: #8f3793;
  border-color: #8f3793;
}

.btn-not-found:not(:disabled):not(.disabled):active:focus, .btn-not-found:not(:disabled):not(.disabled).active:focus,
.show > .btn-not-found.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.ver-completo-btn {
  background-color: #0072bb!important;
  border-color: #0072bb!important;
}
.ver-completo-btn:hover {
  background-color: #0481D2!important;
  border-color: #0481D2!important;
}

#productos p u em img {max-width: 100%;}

/*

.sortable-chosen:not(.sortable-drag) .list-group-item
{
  background: #e2e2e2!important;
}

.sortable-chosen:not(.sortable-drag).favorite-resource-list-item
{
  background: #e2e2e2!important;
}
*/
