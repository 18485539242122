/**
 * Botones - .btn
 */

.btn {
    color: #fff;
    font-family: 'Encode Sans';
    font-weight: 400;
}

.btn.btn-bold {
    font-weight: 600;
}

/* Primary */
.btn-primary {
    background-color: #0072BB;
    border-color: #0072BB;
}

.btn-primary:hover {
    background-color: #0481d2;
    border-color: #0481d2;
}

.btn-primary:focus, .btn-primary.focus {
    color: #fff !important;
    background-color: #0481d2;
    border-color: #0481d2;
    box-shadow: 0 0 0 0.2rem #37bbed;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    background-color: #0072BB;
    border-color: #0072BB;
}

.btn-outline-primary {
    color: #37bbed;
    border-color: #37bbed;
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #37bbed;
    border-color: #37bbed;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
    color: #fff;
    border-color: #37bbed;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
    background-color: #30999a;
    border-color: #30999a;
}

/* Secondary */
.btn-secondary {
    background-color: #8f3793;
    border-color: #8f3793;
}

.btn-secondary:hover {
    background-color: #5d348e;
    border-color: #5d348e;
}

.btn-secondary:focus, .btn-secondary.focus {
    background-color: #8f3793;
    border-color: #8f3793;
    box-shadow: 0 0 0 0.2rem rgb(130 138 145 / 50%);
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    background-color: #8f3793;
    border-color: #8f3793;
}

.btn-outline-secondary {
    color: #5d348e;
    border-color: #5d348e;
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #5d348e;
    border-color: #5d348e;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
    color: #fff;
    border-color: #5d348e;
}

/* Success */
.btn-success {
    background-color: #0072BB;
    border-color: #0072BB;
}

.btn-success:hover {
    background-color: #0481d2;
    border-color: #0481d2;
}

.btn-success:focus, .btn-success.focus {
    background-color: #0481d2;
    border-color: #0481d2;
    box-shadow: none;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
    background-color: #0481d2;
    border-color: #0481d2;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
    background-color: #0072BB;
    border-color: #0072BB;
    box-shadow: none;
}

.btn-outline-success {
    color: #37bbed;
    border-color: #0072BB;
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #0481d2;
    border-color: #0481d2;
}

.btn-outline-success:focus, .btn-outline-success.focus {
    color: #fff;
    border-color: #0072BB;
    background-color: #37bbed;
    border-color: #0072BB;
    box-shadow: none;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
    background-color: #37bbed;
    border-color: #37bbed;
}

