$audioplayer-disabled-color: #BBB;

$audioplayer-elements-space: 12px;


.audio-responsive
{
    width: 0px;
    height: 0px;
    visibility: hidden;
}

.audioplayer
{
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    width: 98%;
    /* height: 96px; */
    align-items: center;
    border: none;
    border-radius: 4px;
    /* background: transparent; */
    margin-bottom: 30px;

    .audioplayer-playpause
    {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 48px;
        height: 48px;
        border-radius: 50%;
        cursor: pointer;
        transition: all .2s ease-in-out;
        background: rgba(0, 0, 0, 0.1);
    }

    .audioplayer-bar
    {
        position: relative;
        display: flex;
        margin: 0px 12px 0px 20px;
        height: 12px;
        flex-basis: 0;
        flex-grow: 1;
        /* cursor: pointer; */
        top: -1px;

        > div
        {
            position: absolute;
            left: 0;
            top: 5px;
        }

        .audioplayer-bar-bg
        {
            background: #bfbfbf;
            width: 100%;
            height: 6px;
            border-radius: 10px;
        }

        .audioplayer-bar-loaded
        {
            z-index: 1;
            height: 6px;
            background: #BFBFBF;
            border-radius: 10px;
        }

        .audioplayer-bar-played
        {
            flex-direction: row-reverse;
            z-index: 2;
            height: 6px;
            background: black;
            border-radius: 10px;

            &::after {
                display: flex;
                position: absolute;
                content: '';
                box-sizing: border-box;
                top: -4px;
                right: -1px;
                margin-right: -5px;
                width: 14px;
                height: 14px;
                border: 2px solid #000;
                background-color: #000;
                border-radius: 16px;
            }
        }

        .audioplayer-bar-played-input
        {
            appearance: none;
            -webkit-appearance: none;
            flex-direction: row-reverse;
            z-index: 4;
            height: 6px;
            background: none;
            border-radius: 10px;
            width: 100%;
            position: absolute;
            left: 0;
            top: 5px;
            cursor: pointer;

            &::-webkit-slider-thumb
            {
                appearance: none;
                -webkit-appearance: none;
                width: 14px;
                height: 14px;
                background-color: rgba(0, 0, 0, 0);
                border-radius: 16px;
                cursor: pointer;
            }

            &::-moz-range-thumb
            {
                appearance: none;
                -webkit-appearance: none;
                width: 14px;
                height: 14px;
                background-color: rgba(0, 0, 0, 0);
                border-color: rgba(0, 0, 0, 0);
                border-radius: 16px;
                cursor: pointer;
            }
        }
    }

    .audioplayer-time
    {
        display: flex;
        font-size: 18px;
        color: #000;
        font-weight: normal;

        margin-right: $audioplayer-elements-space;

        .audioplayer-time-current
        {

            &::after
            {
                content: '|';
                position: relative;
                padding: 0px 6px;
            }
        }
    }

    .audioplayer-volume-bar
    {
        display: flex;
        align-items: center;
        position: relative;

        background-color: #BFBFBF;
        border-radius: 10px;



        .audioplayer-volume-adjust
        {
            display: flex;
            align-items: center;
            margin-left: 8px;
        }
        .audioplayer-volume-adjust
        {
            position: absolute;
            left: -9px;
            height: 6px;
            background-color: #000000;
            border-radius: 10px;

            &::after
            {
                height: 14px;
                width: 14px;
                background: black;
                position: absolute;
                content: '';
                border-radius: 50%;
                top: -4px;
                right: -10px;
            }
        }

        .audioplayer-volume-adjust-input
        {
            appearance: none;
            -webkit-appearance: none;
            display: flex;
            width: 100%;
            height: 6px;
            cursor: pointer;

            z-index: 2;
            background-color: unset;

            &::-webkit-slider-thumb
            {
                appearance: none;
                -webkit-appearance: none;
                width: 14px;
                height: 14px;
                background-color: rgba(0, 0, 0, 0);
                border-radius: 16px;
                cursor: pointer;
            }

            &::-moz-range-thumb
            {
                appearance: none;
                -webkit-appearance: none;
                width: 14px;
                height: 14px;
                background-color: rgba(0, 0, 0, 0);
                border-color: rgba(0, 0, 0, 0);
                border-radius: 16px;
                cursor: pointer;
            }

            &::-webkit-slider-track
            {
                appearance: none;
                -webkit-appearance: none;
                width: 14px;
                height: 14px;
                background-color: rgb(0, 0, 0);
                border-radius: 16px;
                cursor: pointer;
            }
        }

        &.vertical{
            width: 100px;
            transform: rotate(270deg);
            margin: -0 -0 52px -44px;
            transition: opacity 0.3s;

            input[type=range] {width:100px;}
            input[type=range]::-webkit-slider-thumb{cursor: unset;}
        }
    }

    .audioplayer-volume-button
    {
        display: flex;
        align-items: center;
        width: 24px;
        height: 24px;
        cursor: pointer;

        span
        {
            display: flex;
            width: 8px;
            height: 10px;
            background-color: #000000;
            position: relative;
            border-radius: 1px;
            left: -4px;
            top: -1px;

            &::before, &::after {
                content: '';
                position: absolute;
            }

            &::before {
                width: 0;
                height: 0;
                border-top: 10px solid transparent;
                border-right: 10px solid #000000;
                border-bottom: 10px solid transparent;
                border-left: none;
                top: -5px;
            }
        }
    }

    &.audioplayer-mute
    {
        .audioplayer-volume-button
        {
            span
            {
                background-color: #000;
                &::before
                {
                    border-right: 9px solid #000;
                }
            }
        }
    }
    &:not(.audioplayer-mute)
    {
        .audioplayer-volume-button
        {
            span
            {
                &:after
                {
                    left: 12px;
                    top: 0px;
                    width: 12px;
                    height: 12px;
                    border: 6px double #000000;
                    border-width: 6px 6px 0 0;
                    border-radius: 0 12px 0 0;
                    transform: rotate(45deg);
                }
            }
        }
    }

    &.audioplayer-playing
    {
        .audioplayer-playpause
        {
            background: rgba(0, 0, 0, 0.1);
            border: none;

            span {
                content: '';
                display: flex;
                justify-content: space-between;
                width: 12px;
                height: 18px;

                &::before, &::after {
                    content: '';
                    width: 4px;
                    height: 18px;
                    background-color: #000;
                }
            }
        }
    }
    &:not(.audioplayer-playing)
    {
        .audioplayer-playpause
        {
            span
            {
                content: '';
                justify-content: center;
                width: 0;
                height: 0;
                margin-left: 6px;
                border-top: 12px solid transparent;
                border-right: none;
                border-bottom: 12px solid transparent;
                border-left: 20px solid #000000;
            }
        }
    }

    &.audioplayer-disabled
    {
        .audioplayer-playpause
        {
            cursor: not-allowed;

            span
            {
                border-left: 20px solid $audioplayer-disabled-color;
            }
        }
    }
}

.reproductor-panel p
    {
        width: 925px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        text-align: left;
    }

@media screen and (max-width: 1024px)
{
    .audio-btn-container
    {
        gap: 10px;
    }

    .audioplayer-bar
    {
        margin: 0px 5px 0px 20px;
    }

    .audioplayer-volume-bar
    {
        width: 90px;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1401px)
{
}

@media screen and (min-width: 1402px) and (max-width: 1600px)
{
}

@media screen and (max-width: 991px)
{
    .reproductor-container
    {
        margin-bottom: 25px;
    }

    .audioplayer
    {
        position: relative;
        margin-bottom: 45px;
    }

    .reproductor-panel p
    {
        width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
        // white-space: nowrap;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-align: left;
    }

    .audio-btn-container
    {
        gap: 10px;
        padding-right: 5px;
    }

    .audioplayer-bar
    {
        margin: 0px 30px 0px 20px;
    }

    .audioplayer-time
    {
        margin: 0!important;
        position: absolute;
        right: 0;
        top: 50px;
    }

    .audioplayer-time-duration
    {
        right: -25px;
        margin-right: 0!important;
    }

    .audioplayer-volume-bar
    {
        width: 60px;
    }

}

@media (max-width: 1401px)
{
}

@media (max-width: 1199px)
{
}

@media (max-width: 991px)
{
}
