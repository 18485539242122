
$favorite-item-normal-color: black;
$favorite-item-hover-color: #37bbed;
$favorite-item-selected-color: #0072bb;

$favorite-item-delete-color: #37bbed;

.favorite-resource-list
{
    .favorite-resource-list-header
    {
        display: flex;
        justify-items: center;
        .favorite-resource-list-add-button
        {
            color: #34babc;
            cursor: pointer;

            > i
            {
                font-size: 30px;
            }
            &:hover
            {
                > i
                {
                    color: lighten(#34babc, 20%);
                }
            }
        }
    }

    .favorite-resource-list-content
    {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column !important;

        .favorite-resource-list-item
        {
            width: 100%;

            > div
            {
                text-decoration: none;
                display: flex;
                align-items: center;
                
                border-left: 3px solid $white !important;
                padding: 6px 10px;
                cursor: pointer;

                color: $black;

                position: relative;

                .favorite-resource-list-item-label
                {
                    font-size: 18px!important;
                    font-weight: 500;

                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    color: $favorite-item-normal-color;

                    transition: all 0.18s;
                    width: calc(100% - 42px);
                }
                .favorite-resource-list-item-cancel
                {
                    color: $favorite-item-delete-color;
                    //color: lighten($red, 20%);
                    font-size: 18px !important;
                    cursor: pointer;
                    margin-left: 4px;
                    margin-right: 4px;

                    position: absolute;
                    right: 26px;
                    &:hover
                    {
                        color: darken($favorite-item-delete-color, 20%);
                        //color: $red;
                    }
                }
                .favorite-resource-list-item-badge
                {
                    padding: 4px;
                    right: 2px;
                    position: absolute;
                }
            }

            &:hover
            {
                //background: rgba(0, 0, 0, 0.03);

                > div
                {
                    border-left: 3px solid lighten($celeste, 20%) !important;
                    .favorite-resource-list-item-label
                    {
                        padding-left: 5px;
                        text-decoration: underline;
                        color: $favorite-item-hover-color;
                    }
                }
            }

            &.active
            {
                //background: rgba($celeste, 0.3);
                > div
                {
                    border-left: 3px solid $celeste !important;
                    .favorite-resource-list-item-label
                    {
                        padding-left: 5px;
                        text-decoration: underline;
                        color: $favorite-item-selected-color;
                    }
                }
            }
        }
    }
}

.favorite-like-item
{
    .list-group-item
    {
        background-color: #fff;
    }
    &:hover
    {
        .list-group-item
        {
            //background-color: rgb(238, 238, 238);
            background-color: rgb(247, 247, 247);
        }
    }
    &.dragging
    {
        .list-group-item
        {
            background: #e2e2e2!important;
        }
    }
}
